<template>
  <div></div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "MainLayout",
  components: {},
  async mounted() {
    console.log("mounted", this.$route.query.token);
    this.setToken(this.$route.query.token);
    this.setUserType(this.$route.query.type);
    await this.getUserDetail();
    location.href = "/";
  },
  methods: {
    ...mapActions("auth", ["getUserDetail"]),
    ...mapMutations("auth", ["setUserType", "setToken"]),
  },
};
</script>
